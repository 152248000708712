import React from 'react'
import AppHeader from '@conponents/../../src/components/Header'
import AppLayout from '@conponents/../../src/components/layout'
import Cursos from '@conponenets/../../src/components/services'



const index = () => {
  return (
    <div>
      <header id='header'>
    <AppHeader />
  </header>
  <section>
        <div id='backImageCursos' className="banner position-relative">
        <div className='title-banner' id='instituition-Banner'>
        <h1 style={{color:'#00761F'}}>Licenciatura</h1>
        <h5 style={{color:'black'}}>Formação Acadêmica para o Futuro.</h5>
        <h6 style={{color:'#000000'}}>Preparando os alunos para o exercício do ensino em diversas áreas do conhecimento.</h6>
        </div>
        </div>
    </section>
    <main className="px-3 px-md-5 px-xl-5 px-xxl-5 py-2">
        <br />
        <div className="row flex-row-reverse">
        <div className="col-12 col-md-8 col-lg-9 col-xl-10 col-xxl-10">
        <br />
        <div className="details-magazine">
            <p className="f-reg f-20">
                <b style={{textTransform: 'capitalize'}} className="title-apresentation display-6 mb-3 text-success f-24">Cursos de Licenciatura</b><br />
                <br />
                Os cursos de licenciatura são programas acadêmicos que capacitam os estudantes a se tornarem educadores em diversas áreas do conhecimento, como Letras, Matemática, História, Biologia, Geografia, entre outras. A formação visa proporcionar aos futuros professores uma sólida base teórica e prática, com ênfase no desenvolvimento de habilidades pedagógicas, conhecimento específico da disciplina escolhida e compreensão das necessidades dos estudantes.
                <br />
                <br />

                <p>
                Esses cursos, geralmente com duração de 3 a 4 anos, preparam o aluno para atuar tanto na educação básica (ensino infantil, fundamental e médio) quanto, em alguns casos, no ensino superior. Além disso, os programas de licenciatura proporcionam uma formação completa que envolve tanto o domínio do conteúdo da área específica quanto a capacidade de transmitir esse conhecimento de maneira eficaz, inclusiva e criativa.
                </p>

                <br />

                <p>
                    <strong>Escolha o seu futuro hoje:</strong> Um curso no nosso instituto superior é o primeiro passo para transformar sua paixão em uma carreira de sucesso, com conhecimento de excelência e oportunidades únicas de crescimento
                </p>
                <br />
                </p>
                </div>
                <Cursos />
                
            </div>        
        <div className="col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2">
            <a href="/jornadacientifica " style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-success mt-2 f-reg d-flex justify-content-between">
            <span className="f-reg ">
            Licenciatura
            </span>
            <i className="bi text-success bi-arrow-right" />
            </a>
            <a href="# " style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
            <span className="f-reg ">
            Pós-Graduação
            </span>
            <i className="bi text-black bi-arrow-right" />
            </a>
            <a href="/unidade_organica " style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-black mt-2 f-reg d-flex justify-content-between">
            <span className="f-reg ">
            Unidade ôrganica
            </span>
            <i className="bi text-success bi-arrow-right" />
            </a>
            <a href="#  " style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-black mt-2 f-reg d-flex justify-content-between">
            <span className="f-reg ">
            Unidade Curriculares
            </span>
            <i className="bi text-success bi-arrow-right" />
            </a>
        </div>
        </div>
        <br />
        <br />

    </main>
    <AppLayout />
    </div>
  )
}

export default index
