import React from 'react'
import AppHeader from '../../components/Header'
import AppLayout from '../../components/layout'

const Taxa = () => {
  return (
    <div>
          <div>
    <header id='header'>
    <AppHeader />
  </header>
  <section>
        <div id='backImageJornada' className="banner position-relative">
        <div className='title-banner' id='instituition-Banner'>
        <h1 style={{color:'red'}}>Jornada Científica</h1>
        <h5 style={{color:'black'}}>Sejam todos bem-vindos à Jornada Científica.</h5>
        <h6 style={{color:'black'}}>Aqui, você terá acesso a uma programação repleta de conteúdos exclusivos, incluindo apresentações de pesquisa.</h6>
        </div>
        </div>
    </section>
    <main className="px-3 px-md-5 px-xl-5 px-xxl-5 py-2">
        <br />
        <div className="row flex-row-reverse">
        <div className="col-12 col-md-8 col-lg-9 col-xl-10 col-xxl-10">
        <br />
        <div className="details-magazine">
            <b style={{textTransform: 'capitalize'}} className="title-apresentation display-6 mb-3 text-success f-24">Taxa de participação</b>
            <br />
            <br />
            A participação na nossa Jornada Científica oferece uma oportunidade única de imersão em um evento de alto nível, com acesso a palestras, workshops, apresentações de pesquisas e muito mais. Para garantir a sua presença e a qualidade da experiência de todos os envolvidos, estabelecemos uma taxa de participação que cobre os custos de organização, infraestrutura e materiais.
            <br />
            <br />
            <b style={{textTransform: 'capitalize'}} className=" display-7 mb-3 text-success f-24">Taxa de participação</b>

            <ul>
                <li>Estudantes: <strong>500,00kz</strong>
                </li>
                <li>Profissionais e Pesquisadores: <strong>1.000,00kz</strong></li>
                <li>Participação para o público: <strong>2.500,00kz</strong></li>
            </ul>

            </div>
            <br />
            <p>Sua inscrição inclui acesso completo a todas as atividades da jornada, além de materiais exclusivos, certificados de participação e a possibilidade de network com grandes nomes da ciência e pesquisa.
            <br />    
            <br />    
            Aproveite essa oportunidade para expandir seu conhecimento, compartilhar suas pesquisas e fazer parte de uma comunidade científica crescente e inovadora!</p>
            <br />
        </div>
        
        <div className="col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2">
            <a href="jornadacientifica" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
            <span className="f-reg ">
            O Que é a Jornada Científica?
            </span>
            <i className="bi text-success bi-arrow-right" />
            </a>
            <a href="/Taxa" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-sucess mt-2 f-reg d-flex justify-content-between">
            <span className="f-reg ">
            Taxa de participação
            </span>
            <i className="bi text-success bi-arrow-right" />
            </a>
            <a href="/candidatura" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark  mt-2 f-reg d-flex justify-content-between">
            <span className="f-reg ">
            Candidatura
            </span>
            <i className="bi text-success bi-arrow-right" />
            </a>
        </div>
        </div>
        <br />
        <br />

    </main>
    <AppLayout />
    </div>
    </div>
  )
}

export default Taxa
