import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../assets/logo.png';

function AppHeader() {
  return (
    <section>
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">
        <img className='logoHome' src={Logo} alt="Minha Imagem" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link  style={{textDecoration: 'none',fontSize: '20px'}} href="/">Home</Nav.Link>
            <NavDropdown style={{textDecoration: 'none',fontSize: '20px'}}
            className='dropdown'
              id="nav-dropdown-dark-ispa"
              title="A Instituição"
              menuVariant=""
            >
              <NavDropdown.Item className='item_nav' href="/instituicao">Apresentação</NavDropdown.Item>
              <NavDropdown.Item href="/palavra_do_presidente" >Palavra do Presidente</NavDropdown.Item>
              <NavDropdown.Item href="/organigrama" >Organigrama</NavDropdown.Item>
              <NavDropdown.Item href="/missao_visao_valores" >Missão Valores e Visão</NavDropdown.Item>
              <NavDropdown.Item href="/apoioSocial" >Açção Social</NavDropdown.Item>
              <NavDropdown.Item href="/perguntas_frequentes" >Perguntas Frequentes</NavDropdown.Item>
              <NavDropdown.Item style={{marginLeft: '1px'}} href="/" >Projectos</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown style={{textDecoration: 'none',fontSize: '20px'}}
            className='dropdown'
              id="nav-dropdown-dark-ispa"
              title="Internacional"
              menuVariant=""
            >
              <NavDropdown.Item className='item_nav' href="/internacional" >Porque Ispatlantida?</NavDropdown.Item>
              <NavDropdown.Item href="/estudante-internacional" >Estudante Internacional</NavDropdown.Item>
              <NavDropdown.Item href="/alojamento" style={{marginLeft: '1px'}} >Alojamento</NavDropdown.Item>
              <NavDropdown.Item href="/preços" style={{marginLeft: '1px'}} >Preçarios</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown style={{textDecoration: 'none',fontSize: '20px'}}
            className='dropdown'
              id="nav-dropdown-dark-example"
              title="Programa"
              menuVariant=""
            >
              <NavDropdown.Item href="/Licenciatura">Licenciatura</NavDropdown.Item>
              <NavDropdown.Item href="/" > Pós-Graduação</NavDropdown.Item>
              <NavDropdown.Item href="/unidade_organica" >Unidade Orgânica</NavDropdown.Item>
              <NavDropdown.Item style={{marginLeft: '1px'}} href="/" >Unidade Currilares</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown style={{textDecoration: 'none',fontSize: '20px'}}
            className='dropdown'
              id="nav-dropdown-dark-example"
              title="Investigação"
              menuVariant=""
            >
              <NavDropdown.Item href="/jornadacientifica">Jornada Científica</NavDropdown.Item>
              <NavDropdown.Item href="/" style={{marginLeft: '1px'}} >Laboratório</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown style={{textDecoration: 'none',fontSize: '20px'}}
            className='dropdown'
              id="nav-dropdown-dark-example"
              title="Recursos"
              menuVariant=""
            >
              <NavDropdown.Item href="/">Calendário Acadêmicos</NavDropdown.Item>
              <NavDropdown.Item href="/" >Calendário Científico</NavDropdown.Item>
              <NavDropdown.Item href="/" >Regulamento de Estudante</NavDropdown.Item>
              <NavDropdown.Item style={{marginLeft: '1px'}} href="/" >Biblioteca Digital</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown style={{textDecoration: 'none',fontSize: '20px'}}
            className='dropdown'
              id="nav-dropdown-dark-example"
              title="Comunicação"
              menuVariant=""
            >
              <NavDropdown.Item href="/anuncio">Eventos</NavDropdown.Item>
              <NavDropdown.Item style={{marginLeft: '1px'}} href="/noticia" >Noticias</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="https://portalonline.icsgisabelinha.com/" target='_blank'  style={{textDecoration: 'none',fontSize: '20px'}}> Portal Online </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </section>
  );
}

export default AppHeader;