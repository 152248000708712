import React from 'react'
import AppHeader from '../../components/Header'
import AppLayout from '../../components/layout'


const index = () => {
  return (
    <div>
    <header id='header'>
    <AppHeader />
  </header>
  <section>
        <div id='backImageJornada' className="banner position-relative">
        <div className='title-banner' id='instituition-Banner'>
        <h1 style={{color:'red'}}>Jornada Científica</h1>
        <h5 style={{color:'black'}}>Sejam todos bem-vindos à Jornada Científica.</h5>
        <h6 style={{color:'black'}}>Aqui, você terá acesso a uma programação repleta de conteúdos exclusivos, incluindo apresentações de pesquisa.</h6>
        </div>
        </div>
    </section>
    <main className="px-3 px-md-5 px-xl-5 px-xxl-5 py-2">
        <br />
        <div className="row flex-row-reverse">
        <div className="col-12 col-md-8 col-lg-9 col-xl-10 col-xxl-10">
        <br />
        <div className="details-magazine">
            <p className="f-reg f-20">
                <b style={{textTransform: 'capitalize'}} className="title-apresentation display-6 mb-3 text-success f-24">O que é a Jornada Científica?</b><br />
                <br />
                A jornada científica é um evento acadêmico e/ou científico que reúne pesquisadores, estudantes, profissionais e outros interessados para a apresentação, discussão e divulgação de pesquisas, estudos e avanços em diversas áreas do conhecimento. Esse tipo de evento visa promover a troca de ideias, o desenvolvimento de novos conhecimentos e o fortalecimento da colaboração entre diferentes áreas de pesquisa.
                <br />
                <br />

                <p>As Jornadas Científicas do ISP- Atlântida realizadas anualmente consistem num espaço de partilha de conhecimento e troca de experiências científicas entre investigadores, docentes, discentes e outros profissionais do sector público e privado. Por outro lado, propõe-se, igualmente, motivar a comunidade estudantil para o desenvolvimento de habilidades relativas à investigação científica</p>
                <p>
                Deste modo, as 6ª Jornadas Científicas do ISPA que decorrerão entre <strong>14 - 18 de  Abril de 2025</strong> incluirão um conjunto de actividades científicas, tais como, mesas redondas e palestras, sendo um fórum privilegiado de incentivo ao espírito crítico-reflexivo, criativo e investigativo das pessoas envolvidas, enquadrados nos desafios dos 50 anos de caminhada de Angola à independência, olhando para as perspectivas sócio-económica.
                </p>
                <p>
                O tema desta 6ª edição das Jornadas Científicas do ISPA 50 Anos de Independência: <strong>O Papel das Universidades na Inovação e no Crescimento de Angola.”</strong> quer debater os diversos ângulos das estratégias e processos que podem incentivar o desenvolvimento sustentável de Angola, numa altura que se prepara para comemorar o cinquentenário da sua independência.
                </p>
            </p>
            
            <p className="f-reg f-20">
                <b style={{textTransform: 'capitalize'}} className=" display-7 mb-3 text-success f-24">Objetivos principais da jornada científica</b><br />
                <br />
             <strong>1º - </strong>Sensibilizar e promover discussões da ciência aberta de forma multidisciplinar entre docentes, investigadores, discentes, gestores da ciência e público em geral.<br />

             <strong>2º - </strong>Reflectir sobre as perspectivas do desenvolvimento socio-económico de Angola, destacando os avanços e recuos, numa altura que se prepara para comemorar o cinquentenário da sua independência.<br />   

            </p>

            <p>Nas nossas jornadas, há também a premiação de melhores trabalhos, o que motiva ainda mais a participação dos pesquisadores e estudantes. As jornadas científicas são importantes para o crescimento da ciência, a formação de novos pesquisadores e a promoção da educação científica.</p>
            <br />
            </div>
            </div>        
        <div className="col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2">
            <a href="/jornadacientifica " style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dsuccess mt-2 f-reg d-flex justify-content-between">
            <span className="f-reg ">
            O Que é a Jornada Científica?
            </span>
            <i className="bi text-success bi-arrow-right" />
            </a>
            <a href="/Taxa" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
            <span className="f-reg ">
            Taxa de participação
            </span>
            <i className="bi text-success bi-arrow-right" />
            </a>
            <a href="/candidatura" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
            <span className="f-reg ">
            Candidatura
            </span>
            <i className="bi text-success bi-arrow-right" />
            </a>
        </div>
        </div>
        <br />
        <br />

    </main>
    <AppLayout />
    </div>
  )
}

export default index