import React, { useState } from 'react';
import AppHeader from '../../../components/Header';
import AppLayout from '../../../components/layout';
import emailjs from 'emailjs-com';
import { jsPDF } from 'jspdf'; 
import './Enrollment.css';

emailjs.init("JLTmM-bpYmvRk5e1H");

const Enrollment = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [objective, setObjective] = useState('');
  const [description, setDescription] = useState('');
  const [sucesso, setSucesso] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      name,
      email,
      objective,
      description,
    };

    emailjs.send('service_1ndws56', 'template_mt9jt27', templateParams, 'JLTmM-bpYmvRk5e1H')
      .then((response) => {
        setSucesso(true);

        generatePDF();

        setName('');
        setEmail('');
        setObjective('');
        setDescription('');
        setTimeout(() => setSucesso(null), 3000);
      })
      .catch((error) => {
        setSucesso(false);
        setTimeout(() => setSucesso(null), 3000);
      });
  };

  const generatePDF = () => {
    const doc = new jsPDF();


    doc.setFont('helvetica', 'normal');

    doc.setDrawColor(0, 128, 0);
    doc.setLineWidth(2); 
    doc.rect(10, 10, 190, 280); 


    doc.setFontSize(16);
    doc.text('Agradecemos sua Candidatura!', 20, 40);

    doc.setFontSize(12);
    doc.text('Prezado(a) ' + name + ',', 20, 50);
    doc.text('Agradecemos imensamente sua candidatura para a Jornada Científica. ', 20, 60);
    doc.text('A nossa equipe entrará em contato com você em breve por e-mail.', 20, 70);
    doc.text('Caso tenha alguma dúvida, não hesite em nos contatar.', 20, 80);
    doc.text('Abaixo estão os detalhes fornecidos em sua candidatura:', 20, 90);

    doc.text(`Nome do Autor: ${name}`, 20, 100);
    doc.text(`Email: ${email}`, 20, 110);
    doc.text(`Objetivo: ${objective}`, 20, 120);
    doc.text(`Descrição: ${description}`, 20, 130);

    doc.text('Atendimento: jornada.cientifica@ispatlantida.co.ao', 20, 150);
    doc.text('Envia o seu trabalho para por meio deste emial acima.', 20, 160);

    doc.save(`${name}_Candidatura_Jornada_Cientifica.pdf`);

  };

  return (
    <div>
      <header id='header'>
        <AppHeader />
      </header>
      <section>
        <div id='backImageJornada' className="banner position-relative">
          <div className='title-banner' id='instituition-Banner'>
            <h1 style={{ color: 'red' }}>Jornada Científica</h1>
            <h5 style={{ color: 'black' }}>Inscrição de Candidaturas para a Jornada Científica.</h5>
            <h6 style={{ color: 'black' }}>Participe e compartilhe sua pesquisa na nossa jornada!</h6>
          </div>
        </div>
      </section>
      <main className="px-3 px-md-5 px-xl-5 px-xxl-5 py-2">
        <br />
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-xl-4 mx-auto">
              <form onSubmit={handleSubmit} className="form">
                <h2>Candidatura da 6ª Edição da Jornada Científica</h2>
                <div className="input-group">
                  <label htmlFor="nome">Nome do Autor</label>
                  <input
                    type="text"
                    id="nome"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    placeholder="Seu nome completo"
                  />
                </div>
                
                <div className="input-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Seu email"
                  />
                </div>
                
                <div className="input-group">
                  <label htmlFor="objetivo">Objetivo</label>
                  <input
                    type="text"
                    id="objetivo"
                    value={objective}
                    onChange={(e) => setObjective(e.target.value)}
                    required
                    placeholder="Qual o seu objetivo?"
                  />
                </div>

                <div className="input-group">
                  <label htmlFor="descricao">Descrição</label>
                  <textarea
                    id="descricao"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    maxLength="200"
                    required
                    placeholder="Descreva brevemente seu objetivo (Máx. 200 caracteres)"
                  />
                </div>
                <button type="submit" className="btn-submit">Enviar Candidatura</button>
              </form>
              {sucesso !== null && (
                <div className={`popup ${sucesso ? 'success' : 'error'}`}>
                  <span className="icon">{sucesso ? '✔' : '✘'}</span>
                  <p>{sucesso ? 'Candidatura realizada com sucesso!' : 'Erro ao enviar a candidatura.'}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <AppLayout />
    </div>
  );
};

export default Enrollment;
