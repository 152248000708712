import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './App.js';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';


// página home
import Home from './pages/home/home';

// páginas da seçção Instituição
import Instituicao from './pages/instituicao/index.js';
import OrganigramaInstituicao from './pages/instituicao/Organigrama/index.js';
import LegalidadeInstituto from './pages/instituicao/legalidade/index.js';
import MissaInstituicaoInstituicao from './pages/instituicao/missao_visao_valores/index.js';
import PalavraPresidenteInstituicao from './pages/instituicao/palavra_do_presidente/index.js';
import PerguntasFrequentesInstituicao from './pages/instituicao/perguntas_frequentes/index.js';
import ProjetosInstituicao from './pages/instituicao/projetos/index.js';
import AccaoSocial from './pages/instituicao/apoioSocial/index.js';

// páginas da seçção Programas
import Brochura from './pages/programas/brochura_informativa/index.js';
import Licenciatura from './pages/programas/cursos_profissionais/index.jsx';
import Graduacao from './pages/programas/pos_graduacao/index.js';
import Unidade from './pages/programas/unidade_curricular/index.js';
import UnidadeOrganica from './pages/programas/unidade_organica/index.js';
import DocentesCS from './pages/programas/unidade_organica/ciencia_saude/docentesCS/index.js';
import MissaoCS from './pages/programas/unidade_organica/ciencia_saude/missao_visao_valoresCS/index.js';
import ProgramaCS from './pages/programas/unidade_organica/ciencia_saude/programas/index.js';
import ProjetoCS from './pages/programas/unidade_organica/ciencia_saude/projetos/index.js';
import CienciaSaude from './pages/programas/unidade_organica/ciencia_saude/index.js';

// páginas da seçção Comunicação
import Comunicacao from './pages/comunica/index.js';
import Noticia from './pages/comunica/noticia/index.js';
import Anuncio from './pages/comunica/anuncio/index.js';

//Páginas da seção de internacional
import PorqueISpa from './pages/internacional/PorqueISpa.js'
import EstudanteInternacional from './pages/internacional/estudante/index.js'
import CursosInt from './pages/internacional/cursos/index.js'
import Precos from './pages/internacional/price/index.js'
import Alojamento from './pages/internacional/Alojamento/index.js' 

//Páginas da sesão de recursos 
import Jornada from './pages/recursos/index.jsx'
import Taxa from './pages/recursos/Taxa.jsx'
import Candidatura from './pages/recursos/canditadura.jsx'
import Inscrisao from './pages/recursos/Inscrisao/Enrollment.jsx'

const router = createBrowserRouter([

  // páginas da página Home
  {
    path:"/",
    element:<Home />,
  },
  // páginas da seçção Instituição
  {
    path:"/instituicao",
    element:<Instituicao />,
  },
  {
    path:"/apoioSocial",
    element:<AccaoSocial />,
  },
  // páginas da seçção Programas
  {
    path:"/docentesCS",
    element:<DocentesCS />,
  },
  {
    path:"/missao_visao_valoresCS",
    element:<MissaoCS />,
  },
  {
    path:"/programas",
    element:<ProgramaCS />,
  },
  {
    path:"/ciencia_saude",
    element:<CienciaSaude />,
  },
  {
    path:"/projetos",
    element:<ProjetoCS />,
  },
  {
    path:"/Brochura_informativa",
    element:<Brochura />,
  },
  {
    path:"/Licenciatura",
    element:<Licenciatura />,
  },
  {
    path:"/pos_graduacao",
    element:<Graduacao />,
  },
  {
    path:"/unidade_curricular",
    element:<Unidade />,
  },
  {
    path:"/Unidade_organica",
    element:<UnidadeOrganica />,
  },
  // páginas da seçção Instituição
  {
    path:"/Organigrama",
    element:<OrganigramaInstituicao />,
  },
  {
    path:"/legalidade",
    element:<LegalidadeInstituto />,
  },
  {
    path:"/missao_visao_valores",
    element:<MissaInstituicaoInstituicao />,
  },
  {
    path:"/palavra_do_presidente",
    element:<PalavraPresidenteInstituicao />,
  },
  {
    path:"/perguntas_frequentes",
    element:<PerguntasFrequentesInstituicao />,
  },
  {
    path:"/projetos",
    element:<ProjetosInstituicao />,
  },
  // páginas da seçção Comunicação,
  {
    path:"/comunica",
    element:<Comunicacao />,
  },
  {
    path:"/anuncio",
    element:<Anuncio />,
  },
  {
    path:"/noticia",
    element:<Noticia />,
  },
  // Páginas da seçção Instituição
  {
    path:"internacional",
    element:<PorqueISpa />,
  },
  {
    path:"estudante-internacional",
    element:<EstudanteInternacional />,
  },
  {
    path:"cursos",
    element:<CursosInt />,
  },
  {
    path:"Preços",
    element:<Precos />,
  },
  {
    path:"alojamento",
    element:<Alojamento />,
  },
  //sessção recursos
  {
    path:"/jornadacientifica",
    element:<Jornada />,
  },
  {
    path:"/Taxa",
    element:<Taxa />,
  },
  {
    path:"/candidatura",
    element:<Candidatura />,
  },
  {
    path:"/Inscrição",
    element:<Inscrisao />,
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
