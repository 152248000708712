import Carousel from 'react-bootstrap/Carousel';

var heroData = [
  {
    id: 1,
    image: require('../assets/img-hero1.jpg'),
    title: 'O Primeiro Passo para um Futuro de Sucesso',
    description: ' Aqui, oferecemos o conhecimento, as ferramentas e o apoio que você precisa para alcançar o sucesso. Venha dar o primeiro passo para uma carreira de sucesso, onde seu potencial será desenvolvido ao máximo. O futuro começa com a sua decisão de aprender hoje',
    link: '/instituicao'
  },
  {
    id: 2,
    image: require('../assets/img-hero2.jpg'),
    title: 'O Caminho para o Sucesso Começa Aqui',
    description: 'No nosso Instituto Superior, você encontrará a base sólida que precisa para transformar seus sonhos em realidade. Prepare-se para um futuro cheio de oportunidades, com conhecimento de qualidade e uma formação que faz a diferença. Venha construir seu sucesso conosco',
    link: '#'
  },
  {
    id: 3,
    image: require('../assets/img-hero3.jpg'),
    title: 'Construa seu Futuro Promissor Conosco',
    description: 'Invista no seu futuro hoje para conquistar o sucesso amanhã! No nosso Instituto Superior, preparamos você para os desafios do mundo profissional com qualidade, inovação e dedicação. Venha construir um caminho sólido para um futuro promissor',
    link: '#'
  }
]

function AppHero() {
  return (
    <section id="home" className="hero-block">
       <Carousel>
          {
            heroData.map(hero => {
              return (
                <Carousel.Item key={hero.id}>
                  <img
                    className="d-block w-100"
                    src={hero.image}
                    alt={"slide " + hero.id}
                  />
                  <Carousel.Caption>
                    <h2>{hero.title}</h2>
                    <p style={{color:'#FFFFFF'}}>{hero.description}</p>
                    <a className="btn btn-primary" href={hero.link}>Leia Mais <i className="fas fa-chevron-right"></i></a>
                  </Carousel.Caption>             
                </Carousel.Item>
              );
            })
          }
      </Carousel>
    </section>
  );
}

export default AppHero;