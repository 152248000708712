import React from 'react'
import AppHeader from '../../components/Header'
import AppLayout from '../../components/layout'

const canditadura = () => {
  return (
    <div>
    <header id='header'>
    <AppHeader />
  </header>
  <section>
        <div id='backImageJornada' className="banner position-relative">
        <div className='title-banner' id='instituition-Banner'>
        <h1 style={{color:'red'}}>Jornada Científica</h1>
        <h5 style={{color:'black'}}>Sejam todos bem-vindos à Jornada Científica.</h5>
        <h6 style={{color:'black'}}>Aqui, você terá acesso a uma programação repleta de conteúdos exclusivos, incluindo apresentações de pesquisa.</h6>
        </div>
        </div>
    </section>
    <main className="px-3 px-md-5 px-xl-5 px-xxl-5 py-2">
        <br />
        <div className="row flex-row-reverse">
        <div className="col-12 col-md-8 col-lg-9 col-xl-10 col-xxl-10">
        <br />
        <div className="details-magazine">
            <p className="f-reg f-20">
                <b style={{textTransform: 'capitalize'}} className="title-apresentation display-6 mb-3 text-success f-24">Subscrição da Candidatura</b><br />
                <br />
                Bem-vindo à página de inscrição para a Jornada Científica do nosso Instituto Superior Ispatlantida! Aqui você encontrará todas as informações necessárias para garantir sua participação neste evento inovador, que reúne estudantes, pesquisadores e profissionais da área científica para compartilhar conhecimentos e novas descobertas.
                <br />
            </p>
            <p className="f-reg f-20">
           
            A inscrição será realizada exclusivamente online. Ao acessar o link que estará disponível no momento da abertura das inscrições, você poderá preencher todos os dados necessários e confirmar sua participação na jornada científica.
            <br />
            <br />
            <div style={{ fontFamily: 'Arial, sans-serif' }}>
            <h2 style={{color:'#00761F'}}>Transforme Suas Ideias em Descobertas – Inscreva-se na Jornada Científica de 2025</h2>
            
            <p >Transforme suas ideias em descobertas inovadoras e contribua para o avanço da ciência! A Jornada Científica é uma oportunidade única para você apresentar sua pesquisa, compartilhar seu conhecimento e se conectar com outros jovens cientistas e acadêmicos.</p>
            <p>
            Ao se inscrever, você terá a chance de expor seu trabalho, aprender com especialistas renomados e impulsionar sua carreira acadêmica. Não perca a chance de dar um passo importante em sua jornada de pesquisa – inscreva-se agora e faça parte dessa experiência transformadora, onde suas ideias podem se tornar grandes descobertas para o futuro.
            </p>
            <p style={{fontFamily:'Montserrat", sans-serif'}}><strong>Clique no botão abiaxo de inscrição agora mesmo e dê o primeiro passo para transformar suas ideias em descobertas incríveis</strong></p>
            <a className="btn btn-primary" href="/Inscrição">Candidatura <i className="fas fa-chevron-right"></i></a>
          </div>
            </p>
            <br />
            </div>
            </div>        
        <div className="col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2">
            <a href="/jornadacientifica " style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
            <span className="f-reg ">
            O Que é a Jornada Científica?
            </span>
            <i className="bi text-success bi-arrow-right" />
            </a>
            <a href="/Taxa" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
            <span className="f-reg ">
            Taxa de participação
            </span>
            <i className="bi text-success bi-arrow-right" />
            </a>
            <a href="/candidatura" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-success mt-2 f-reg d-flex justify-content-between">
            <span className="f-reg ">
            Candidatura
            </span>
            <i className="bi text-success bi-arrow-right" />
            </a>
        </div>
        </div>
        <br />
        <br />

    </main>
    <AppLayout />
    </div>
  )
}

export default canditadura
